import React from "react";
import "./Client.css";
import Container from "react-bootstrap/Container";
import newmap from "../../../assets/images/home/newmap.png";
const Client = () => {
  return (
    <>
       <section className="Clients Here">
          <Container>
              <div className="common-heading ptag text-center">
                <h2>We Work For Clients Here</h2>
                <div className="bar"> </div>
                <div className="map-img">
                  <img src={newmap} alt="img5" />
                </div>
              </div>
          </Container>
        </section>
    </>
  );
};

export default Client;
