import React, { useEffect, useState } from "react";
import MenuItems from "./MenuItems";
import { NavLink } from "react-router-dom";
import Header from "../../../Common/header/Header"
import Footer from "../../../Common/footer/Footer";
import Footer1 from "../../../Common/footer/Footer1";
import "./Allproject.css"
import { FaArrowRight } from "react-icons/fa";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import website2 from "../../../assets/images/portfolio/website2.png";

function Project() {
  const [items, setItems] = useState(MenuItems.slice(0, 6));
  const filterItem = (categItem) => {
    const updatedItems = MenuItems.filter((curElem) => {
      return curElem.category === categItem;
    });

    setItems(updatedItems);
  };
  ///modal//

  const [lgShow, setLgShow] = useState(false);
  const [modelimage, setModelimage] = useState({
    image: "",
    name: "",
    description: "",
  });
  const [inactive, setInnactive] = useState("btn66");
  const [active, setActive] = useState("btn65");
  const [all, setAll] = useState(active);
  const [mobile, setMobile] = useState(inactive);
  const [ios, setIos] = useState(inactive);
  const [web, setWeb] = useState(inactive);

  return (
    <>
<Header/>
        <div className="portfolio-page">
          <Container>
            <Row>
              <div className="portfolio-1">
                <Row>
                  <Col lg={6}>
                    <div className="allproject-heding">
                      <h2>Our Work Portfolio</h2>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="filters">
                      <div className="filter-menu">
                        <button
                          className={all}
                          onClick={() => {
                            setItems(MenuItems);
                            setAll(active);
                            setIos(inactive);
                            setMobile(inactive);
                            setWeb(inactive);
                          }}
                        >
                          All
                        </button>
                        <button
                          className={web}
                          onClick={() => {
                            filterItem("Website");
                            setAll(inactive);
                            setIos(inactive);
                            setMobile(inactive);
                            setWeb(active);
                          }}
                        >
                          website
                        </button>
                        <button
                          className={mobile}
                          onClick={() => {
                            filterItem("app");
                            setAll(inactive);
                            setIos(inactive);
                            setMobile(active);
                            setWeb(inactive);
                          }}
                        >
                          Mobile App
                        </button>
                        <button
                          className={ios}
                          onClick={() => {
                            filterItem("ios");
                            setAll(inactive);
                            setIos(active);
                            setMobile(inactive);
                            setWeb(inactive);
                          }}
                        >
                          Ios App
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {items.map((elem) => {
                const { id, name, image, description } = elem;
                return (
                  <Col lg={4}>
                    <div className="frame mt-4">
                      <div className="item-image">
                        <img src={image} alt={name} className="portfolio" />
                        <div className="details">
                        <h1>{name}</h1>
                        <p>{description}</p>
                          <NavLink to="/Projectdetails" className="all-read-more">
                            Read More  <FaArrowRight />
                          </NavLink>
                          {/* <h1>{name}</h1>
                          <p>{description}</p>
                          <Button
                            onClick={() => {
                              setLgShow(true);
                              setModelimage((modelimage) => ({
                                image: image,
                                name: name,
                                desc: description,
                              }));
                            }}
                          >
                            <FaArrowRight />
                          </Button> */}
                        </div>
                      </div>
                    </div>
                  </Col>

                );
              })}
            </Row>
          </Container>
        </div>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title className="m-title">
              {/* <h4 style={{ color: "rgb(19 47 112)" }}>{modelimage.name}</h4> */}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
              <Col sm={6}>
                <div className="pro-description-29">
                  <h4>{modelimage.name}</h4>
                  <p >A smart workflow remains at the center of any excellent experience.</p>
                  
                 
                </div>
                <div className="ind-text-1">
              <h4 style={{ color: "rgb(64 66 70)",fontSize:"17px",paddingTop:"10px" }}>Technology</h4>
              <ul style={{ paddingLeft:"0px",display:"flex",color:"#6a6363",fontSize:"15px" }}>
                <li style={{ paddingRight:"5px"}}> - React js</li>
                <li style={{ paddingRight:"5px"}}> - Bootstrap4 </li>
                <li style={{ paddingRight:"5px"}}> - Css</li>
              </ul>
              <h4 style={{ color: "rgb(64 66 70)" ,fontSize:"17px"  }}>Visit Website</h4>
                    <a href="https://www.beta.soundchatradio.com/"
                    target="_blank"
                    style={{color:"#6a6363",fontSize:"15px" }}
                    >https://www.beta.soundchatradio.com/</a>
              </div>
            
              </Col>
             
              <Col sm={6}>
                <img
                  src={modelimage.image}
                  alt={modelimage.name}
                  className="galler"
                  style={{ width: "100%", marginBottom: "20px" }}
                />
              </Col>
            </Row>
           
            <Row>
            <Col sm={12}>
              <div className="descriptions-p">
            <h4 style={{ color: "rgb(64 66 70)",fontSize:"19px" }}>Project Description</h4>
              <p>A smart workflow remains at the center of any excellent experience. With a powerful digital solution, you can accelerate your 
                    digital transformation to match the particular demands of your industry.
                    A smart workflow remains at the center of any excellent
                experience. With a powerful digital solution, you can accelerate
                your digital transformation to match the particular demands of
                your industry.</p>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
        <Footer1 />
      <Footer />
    </>
  );
}

export default Project;
