import React, { useState, useEffect } from "react";
import Rqform from "./Rqform";
import "./Request.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Container, Row, Col, Button } from "react-bootstrap";

function Request() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <div className="request-form">
        <Container>
          <div className="request" data-aos="zoom-in">
            <h3>Request A Quotation</h3>
            <p>Let's start a conversation.</p>
          </div>
        </Container>
      </div>
      <div className="request-form-1">
        <Container>
          <Row>
            <Col lg={5} md={12} sm={12}>
              <div className="request-form-2">
                <h3>Start a Project</h3>
                <p>
                  <strong>We would love to work on your project.</strong> Tell
                  us a bit about yourself & your project, we'll provide you the
                  quote and time estimation.
                </p>
              </div>
              <div className="steps">
                <div className="step">
                  <div className="step-header">
                    <div className="step-1">1</div>
                    <h4>We'll prepare a proposal</h4>
                    <p>
                      Required scop, timeline and apr.price will be included if
                      you provide us with detail information about a project
                    </p>
                  </div>
                </div>
                <div className="step minimized">
                  <div className="step-header">
                    <div className="step-1">2</div>
                    <h4>Together we discuss it</h4>
                    <p>
                      Let's get acquainted and discuss all the possible variants
                      and options.google Hangouts or Skype usually works great.
                    </p>
                  </div>
                </div>
                <div className="step minimized-1">
                  <div className="step-header">
                    <div className="step-1">3</div>
                    <h4>Let's start building</h4>
                    <p>
                      When the contract is signed,and all goals are set wecan
                      start the first sprint.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={7} md={12} sm={12}>
              <Rqform />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="banner-slide">
        <Container>
          <Row>
            <Col md={12} sm={12}>
              <div className="banner-slide-1">
                <h5>Software full of Performance</h5>
                <p>
                  performance refers to the aspect of the system that is the
                  limiting factor in the end usability of the system. This
                  aspect may change as our underlying technologies improve.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Request;
