import React, { useEffect } from "react";
import "./About.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaHeadphones,
  FaReceipt,
  FaRegSmile,
  FaTabletAlt,
} from "react-icons/fa";
import { FaTransgenderAlt, FaMedapps, FaRegFile } from "react-icons/fa";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Container, Row, Col } from "react-bootstrap";
import team1 from "../../assets/images/team/team1.png";
import team2 from "../../assets/images/team/team2.png";
import team3 from "../../assets/images/team/team3.png";
import team4 from "../../assets/images/team/team4.png";
import team5 from "../../assets/images/team/team5.png";
import team77 from "../../assets/images/team/team77.png";

function About1() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>
      {/* <Topheader/> */}
      {/* <Header />
      <AboutStyles> */}
        <div className="about-area ptb-80">
          <Container>
            <Row>
              <Col lg={12} md={12}>
                <div className="ml-about-content" data-aos="zoom-in">
                  <span className="sub-title">About Us</span>
                  <h2>Who We Are</h2>
                  <div className="bar"></div>
                  <p>
                    IMPETROSYS established since over a decade with vision to
                    achieve global IT services leadership in providing
                    value-added high quality IT solutions to our clients, with
                    expertise in developing custom web & mobile applications for
                    businesses of all sizes and industries Over the years we
                    have managed to build a solid team of software outsourcing
                    professionals that come from various backgrounds and expand
                    the creative potential of the company.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="histry-text">
          <Container>
            <Row>
              <Col lg={4} md={6}>
                <div className="about-text1  our-mission">
                  <i>
                    <FaTransgenderAlt />
                  </i>
                  <h3>Our Mission</h3>
                  <p>
                    By retaining our loyal and satisfied clients, we continue to
                    expand our business worldwide. Our commitment to delivering
                    high-quality work allows us to gain the trust of our
                    clients.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="about-text1 our-vision">
                  <i>
                    {" "}
                    <FaMedapps />
                  </i>

                  <h3>Our vision</h3>
                  <p>
                    Our vision We strive to become the world's most admired and
                    valuable business partner and to make every possible move in
                    the upcoming years for our client’s success.
                  </p>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="about-text1 our-histtory">
                  <i>
                    <FaRegFile />
                  </i>

                  <h3>Our History</h3>
                  <p>
                    IMPETROSYS established since over a decade with vision to
                    achieve global IT services leadership in providing
                    value-added high quality IT solutions to our clients.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="app_featured_area about-usBg ptb-50-100">
          <div className="container">
            <div className="row  app_feature_info  honest_section1">
              <div className="col-lg-6">
                <div className="app_featured_content mobile_cpmpany11">
                  <h4 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">
                    Top Mobile App &amp; Web Development Company in India
                  </h4>

                  <p className="f_400">
                    Impetrosys utilizes its qualities in innovative ideas,
                    developing, portable just as client assistance to make new
                    income-producing open doors for its clients and
                    simultaneously decreasing the overheads while empowering
                    them to rapidly send and better oversee and direct perfect
                    outcomes their organizations.
                  </p>

                  <p className="f_400">
                    We are a software development company that gives perfect
                    edge designing services, helping Fortune 500 enterprises,
                    and solving customers' unbeatable complex issues that
                    consistently arise during their agile development project.
                    Since 2013 we have been a visionary and a trustable software
                    partner globally.
                  </p>

                  <p className="f_400">
                    We embrace change and are constantly evolving. We push and
                    elevate each other with the shared goal of doing awesome
                    work.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="app_fetured_item">
                  <Row>
                    <Col md={6} sm={6} xs={6}>
                      <div
                        className="app_item item_one"
                        data-parallax='{"x": 0, "y": 50}'
                      >
                        <i className="ti-headphone">
                          <FaHeadphones />
                        </i>

                        <h3 className="f_p f_400 f_size_16 w_color l_height45">
                          24x7 Support
                        </h3>
                      </div>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <div
                        className="app_item item_two"
                        data-parallax='{"x": 0, "y": -30}'
                      >
                        <i className="ti-headphone">
                          <FaReceipt />
                        </i>

                        <h3 className="f_p f_400 f_size_16 w_color l_height45">
                          Honest pricing
                        </h3>
                      </div>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <div
                        className="app_item item_three"
                        data-parallax='{"x": 50, "y": 10}'
                      >
                        <i className="ti-headphone">
                          <FaRegSmile />
                        </i>

                        <h3 className="f_p f_400 f_size_16 w_color l_height45">
                          Management
                        </h3>
                      </div>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <div
                        className="app_item item_four"
                        data-parallax='{"x": -20, "y": 50}'
                      >
                        <i className="ti-headphone">
                          <FaTabletAlt />
                        </i>

                        <h3 className="f_p f_400 f_size_16 w_color l_height45">
                          Mobile App Design
                        </h3>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="section-team">
          <div className="section-title" data-aos="zoom-in">
            <h2>Our Dedicated Professionals Who Deliver Unique solutions</h2>
            <div className="bar"></div>
            <p className="mb-3">
              We have best team for your projects and convert your ideas into
              the real world projest.
            </p>
          </div>
          <Swiper
            slidesPerView={5}
            modules={[Autoplay]}
           
            autoplay={true}
            // className="mySwiper"
            spaceBetween={10}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              991: {
                slidesPerView: 3,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },

              575: {
                slidesPerView: 2,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 24,
                resistanceRatio: 0.85,
              },
            }}
          >
            <SwiperSlide>
              {" "}
              <div className="single-person">
                <div className="person-image">
                  <img src={team3} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Mr. Suraj Pandagre</h3>
                  <p>Sr. FullStack Developer</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-person">
                <div className="person-image">
                  <img src={team4} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Miss. Shailesh Bhadoriya</h3>
                  <p> Sr. IOS developer.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-person">
                <div className="person-image">
                  <img src={team1} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Miss. Dipali Jain</h3>
                  <p> Sr. Web designer.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-person">
                <div className="person-image">
                  <img src={team2} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Mr. Kamlesh Rajpoot</h3>
                  <p> Sr. FullStack developer.</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div className="single-person">
                <div className="person-image">
                  <img src={team5} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Mr. Raj Kumar Patel</h3>
                  <p> Sr.Flutter Developer.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="single-person">
                <div className="person-image">
                  <img src={team77} alt="map-imgg" />
                </div>

                <div className="person-info">
                  <h3>Miss.Rashmi Lodhi</h3>
                  <p> Jr.Frontend Developer.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <Counter />
      </AboutStyles>
      <Footer1 />
      <Logoslider />
      <Footer /> */}
    </>
  );
}

export default About1;
