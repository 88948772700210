import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Industri from "../Component/Industry/Industri";
 import Counter from "../Component/Homes/Counter/Counter";

const Industries = () => {
  return (
    <>
    <Header/>
    <Industri/>
   
      <Footer1 />
      <Footer />
    </>
  );
};

export default Industries;
