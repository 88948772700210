
const MenuItems= [
    {
       id: 1,
       image:"http://localhost:3001/static/media/aap6.5777fe35cf27f27a6a4e.png",
       name:"FAMILY FM LTD.Antigua",
       category: "app",
       description: 'Android App -> iOS App'
   },
 
   {
       id: 2,
       image: "http://localhost:3001/static/media/aap2.821213e07e05bbdd8f1f.png",
       name:"Bct Pay",
       category: "app",
       description: 'Android App -> iOS App -> Website'
   },
   {
       id: 3,
       image: "http://localhost:3001/static/media/aap11.4fd4290b3e96faeb1530.png",
       name:"Soundchat Radio",
       category: "app",
       description: 'Android App -> iOS App -> Website'
   },
    {
       id: 4,
       image: "http://localhost:3001/static/media/aap7.bb8e47b2.png",
       name:"Reegur",
       category: "app",
       description: 'Android App -> iOS App -> Website'
   },
     {
       id: 5,
       image: "http://localhost:3001/static/media/aap2.0e6ccab5af8504fe675e.png",
       name:"Payroos",
       category: "app",
       description: 'Android App -> iOS App '
   },
      {
       id: 6,
       image: "http://localhost:3001/static/media/aap6.5777fe35cf27f27a6a4e.png",
       name:"Time less ",
       category: "app",
       description: 'Android App -> iOS App'
   },
       {
       id: 7,
       image: "http://localhost:3001/static/media/aap8.5020f5d3fa7b0698407f.png",
       name:"Rock vibe gospal radio",
       category: "app",
       description: 'Android App -> iOS App '
   },
       {
       id: 8,
       image: "http://localhost:3001/static/media/aap4.8b670e92e228b45a0760.png",
       name:"Catholic radio",
       category: "app",
       description: 'Android App -> iOS App '
   },
       {
       id: 9,
       image: "http://localhost:3001/static/media/website1.74f616e6.png",
       name:"Pragati manav seva sansthan",
       category: "Website",
       description: 'Website '
   },
   {
    id: 10,
    image: "http://localhost:3001/static/media/website2.5ce077e3.png",
    name:"Mahakal takniki shiksha samiti",
    category: "Website",
    description: 'Website '
},

{
    id: 11,
    image: "http://localhost:3001/static/media/website3.a4147a5e.png",
    name:"Pettershell",
    category: "Website",
    description: 'Website '
},
{
    id: 12,
    image: "http://localhost:3001/static/media/website9.74f84a43.png",
    name:"Gihlot consultant",
    category: "Website",
    description: 'Website '
},
 {
    id: 13,
    image: "http://localhost:3001/static/media/website4.da652819.png",
    name:"Film Director",
    category: "Website",
    description: 'Website '
},
  {
    id: 14,
    image: "http://localhost:3001/static/media/website5.fcfd70dc.png",
    name:"Gyanav Solution Private Limited ",
    category: "Website",
    description: 'website '
},
   {
    id: 15,
    image: "http://localhost:3001/static/media/website6.16a0d9ee.png",
    name:"Seemoss",
    category: "Website",
    description: 'website '
},
    {
    id: 16,
    image: "http://localhost:3001/static/media/website7.8d0354e5.png",
    name:"Sanatan Express Logistics",
    category: "Website",
    description: 'website '
},
    {
    id: 17,
    image: "http://localhost:3001/static/media/website8.1990014f.png",
    name:"Legis facilitar Law Firm",
    category: "Website",
    description: 'website '
},
{
    id: 17,
    image: "http://localhost:3001/static/media/website10.277e6006.png",
    name:"kccorganicminerals",
    category: "Website",
    description: 'website '
},

{
    id: 18,
    image:"http://localhost:3001/static/media/aap6.6df08c3d.png",
    name:"FAMILY FM LTD.Antigua",
    category: "ios",
    description: 'Android App -> iOS App '
},
{
id: 19,
image: "http://localhost:3001/static/media/aap1.461408a6afe46edde8f3.png",
name:"Bct Pay",
category: "ios",
description: 'Android App -> iOS App -> Website'
},
{
    id: 20,
    image: "http://localhost:3001/static/media/aap11.4fd4290b3e96faeb1530.png",
    name:"Soundchat Radio",
    category: "ios",
    description: 'Android App -> iOS App -> Website'
},
{
    id: 21,
    image: "http://localhost:3001/static/media/aap3.a8ccbc8d7305df8602cb.png",
    name:"Reegur",
    category: "ios",
    description: 'Android App -> iOS App -> Website'
},
{
    id: 21,
    image: "http://localhost:3001/static/media/aap2.0e6ccab5af8504fe675e.png",
    name:"Payroos",
    category: "ios",
    description: 'Android App -> iOS App '
},
{
    id: 22,
    image: "http://localhost:3001/static/media/aap5.56d24271.png",
    name:"Time less ",
    category: "ios",
    description: 'Android App -> iOS App'
},
{
    id: 23,
    image: "http://localhost:3001/static/media/aap8.7aac068d.png",
    name:"Rock vibe gospal radio",
    category: "ios",
    description: 'Android App -> iOS App '
},
{
    id: 24,
    image: "http://localhost:3001/static/media/aap4.e2c743f7.png",
    name:"Catholic radio",
    category: "ios",
    description: 'Android App -> iOS App '
},
{
    id: 25,
    image: "http://localhost:3000/static/media/website11.98567283e4242bcac9be.png",
    name:"Film Director",
    category: "website",
    description: 'website'
},

];



export default MenuItems;