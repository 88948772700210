import { useState, useEffect } from "react";
import { omit } from "lodash";

const useForm = (callback) => {
  //Form values
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [values, setValues] = useState({});
  const [range, setrange] = useState();
  const [formname, setformname] = useState("");

  //Errors
  const [errors, setErrors] = useState({});
  const isValidFileUploaded = (file) => {
    console.log(file, "file");
    const validExtensions = ["pdf", "plain", "doc"];
    const fileExtension = file.type.split("/")[1];
    return validExtensions.includes(fileExtension);
  };

  const validate = (event, name, value) => {
    switch (name) {
      case "mobilenumber":
        if (!new RegExp(/^[0-9+].{6,12}$/).test(value)) {
          setErrors({
            ...errors,
            mobilenumber: "Mobile Number Should Be  7 To 12 digit number",
          });
        } else {
          let newObj = omit(errors, "mobilenumber");
          setErrors(newObj);
        }
        break;
      case "phonenumber":
        if (!new RegExp(/^[0-9+].{6,12}$/).test(value)) {
          setErrors({
            ...errors,
            phonenumber: "Phone Number Should Be  7 To 12 digit number",
          });
        } else {
          let newObj = omit(errors, "phonenumber");
          setErrors(newObj);
        }
        break;

      case "email":
        if (value.trim().length <= 0) {
          setErrors({
            ...errors,
            email: "Please Enter Your Email Address",
          });
        } else if (
          !new RegExp(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ).test(value)
        ) {
          setErrors({
            ...errors,
            email: "Enter a valid email address",
          });
        } else {
          let newObj = omit(errors, "email");
          setErrors(newObj);
        }
        break;
      case "fname":
        if (value.trim().length <= 0) {
          setErrors({
            ...errors,
            fname: "Please Enter Your First Name",
          });
        } else if (!new RegExp(/^[A-Za-z\s]+$/).test(value)) {
          setErrors({
            ...errors,
            fname: "Only Enter Alphabet",
          });
        } else {
          let newObj = omit(errors, "fname");
          setErrors(newObj);
        }
        break;

      case "startDate":
        if (!startDate) {
          setErrors({
            ...errors,
            startDate: "Please select a start date",
          });
        } else {
          let newObj = omit(errors, "startDate");
          setErrors(newObj);
        }
        break;

      case "endDate":
        if (!endDate) {
          setErrors({
            ...errors,
            endDate: "Please select an end date",
          });
        } else {
          let newObj = omit(errors, "endDate");
          setErrors(newObj);
        }
        break;
      case "businessType":
        if (value.trim().length <= 0) {
          setErrors({
            ...errors,
            businessType: "Please Enter Your Service Requires",
          });
        } else if (!new RegExp(/^[A-Za-z\s]+$/).test(value)) {
          setErrors({
            ...errors,
            businessType: "Only Enter Alphabet",
          });
        } else {
          let newObj = omit(errors, "businessType");
          setErrors(newObj);
        }
        break;

      case "range":
        if (!range || isNaN(range) || range < 1 || range < 5000) {
          setErrors({
            ...errors,
            range: "Please enter a valid value between 1 and 5000",
          });
        } else {
          let newErrors = omit(errors, "range");
          setErrors(newErrors);
        }
        break;

      case "businessdesc":
        if (value.trim().length <= 50) {
          setErrors({
            ...errors,
            businessdesc: "Please Enter Description More then 50 character",
          });
        } else {
          let newObj = omit(errors, "businessdesc");
          setErrors(newObj);
        }
        break;
      case "businessfile":
        console.log(event.target.files.length);
        if (event.target.files.length < 1) {
          setErrors({
            ...errors,
            businessfile: "Please select a file",
          });
        } else if (!isValidFileUploaded(event.target.files[0])) {
          setErrors({
            ...errors,
            businessfile: "Please select only .pdf, .txt, .doc",
          });
        } else {
          let newObj = omit(errors, "businessfile");
          setErrors(newObj);
        }
        break;
      default:
        break;
    }
  };

  //A method to handle form inputs
  const handleChange = (event) => {
    console.log(event.target.required, "event");

    //To stop default events
    event.persist();

    let name = event.target.name;
    let val = event.target.value;
    if (event.target.required === true) {
      validate(event, name, val);
    } else {
      console.error("inside");

      let newObj = omit(errors, name);
      setErrors(newObj);
    }
    //Let's set these values in state
    setValues({
      ...values,
      [name]: val,
      [name + "event"]: event,
      event: event,
    });
  };

  //A method to handle form inputs
  const handleBlur = (event) => {
    console.log(event.target.required, "event");
    //To stop default events
    event.persist();

    let name = event.target.name;
    let val = event.target.value;
    if (event.target.required === true) {
      validate(event, name, val);
    } else {
      let newObj = omit(errors, name);
      setErrors(newObj);
    }

    //Let's set these values in state
    setValues({
      ...values,
      [name]: val,
      [name + "event"]: event,
      event: event,
    });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setformname(event.target.name);

    callback();
  };

  return {
    formname,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  };
};

export default useForm;
