import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";


function Contactform() {
  const showAlert = () => {
    Swal.fire({
      title: "Message Sent",
      text: "Thank you for contacting us.We will get back to you asap.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      //
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      subject: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),

      phone: Yup.string()
        .required("Required")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Phone number is not valid"
        ),
      message: Yup.string()
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      showAlert();
      formik.resetForm();

      try {
        const response = await fetch(
          `https://impetrosys.com/new/api/Webservice/savecontacts`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (response.ok) {
          // Form data sent successfully
          console.log("Form submitted successfully");
          showAlert();
          formik.resetForm(); // Reset form after successful submission
        } else {
          // Error in sending form data
          console.log("Error submitting form");
        }
      } catch (error) {
        console.log("Error submitting form", error);
      }

      // try {
      //   const response = await fetch(
      //     "https://impetrosys.com/new/api/Webservice/savecontacts",
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //         "Access-Control-Allow-Origin": "http://localhost:3000",
      //         "Access-Control-Allow-Methods": "GET, OPTIONS, POST, PUT",
      //         mode: "no-cors",
      //       },
      //       body: JSON.stringify(values),
      //     }
      //   );

      //   if (response.ok) {
      //     // Form data sent successfully
      //     console.log("Form submitted successfully");
      //     showAlert();
      //     formik.resetForm(); // Reset form after successful submission
      //   } else {
      //     // Error in sending form data
      //     console.log("Error submitting form");
      //   }
      // } catch (error) {
      //   console.log("Error submitting form", error);
      // }
    },
  });

  return (
    <>
      <div className="contctform-30">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={6}>
              <lable className="Contactform-lb">Enter Name</lable>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="name"
                  autoComplete="off"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name && formik.touched.name && (
                  <p className="errormessage">{formik.errors.name}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <lable className="Contactform-lb">Enter Email</lable>
              <Form.Group>
                <Form.Control
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <p className="errormessage">{formik.errors.email}</p>
                )}
              </Form.Group>
            </Col>

            <Col md={6}>
              <lable className="Contactform-lb">Enter Mobile Number</lable>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="phone"
                  autoComplete="off"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <p className="errormessage">{formik.errors.phone}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <lable className="Contactform-lb">Enter Subject</lable>
              <Form.Group>
                <Form.Control
                  type="text"
                  name="subject"
                  autoComplete="off"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                />
                {formik.errors.subject && formik.touched.subject && (
                  <p className="errormessage">{formik.errors.subject}</p>
                )}
              </Form.Group>
            </Col>
            <Col md={12}>
              <lable className="Contactform-lb">Enter Message </lable>
              <Form.Control
                as="textarea"
                style={{ height: "108px" }}
                type="message"
                name="message"
                autoComplete="off"
                // multiline
                rows={4}
                value={formik.values.message}
                onChange={formik.handleChange}
              />
              {formik.errors.message && formik.touched.message && (
                <p className="errormessage">{formik.errors.message}</p>
              )}
              <div className="contact-7">
              <button className=" main-btn btn btn-primary">
              SEND
                    </button>
                {/* <button type="Submit" className="main-btn.btn.btn-primary">
                  SEND
                </button> */}
              </div>
            </Col>
          </Row>
        </Form>
      </div>
 
    </>
  );
}

export default Contactform;
