import React, { useState, useEffect } from "react";
import "./Contact.css"
import Contactform from "./Contactform";
import { FaAngleRight } from "react-icons/fa";
import { Container, Row, Col } from "react-bootstrap";
import newyork from "../../assets/images/newyork.png";
import indore from "../../assets/images/indore.png";
import contactsuported from "../../assets/images/footer/contactsuported.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaViber, FaEnvelopeOpenText, FaDribbble } from "react-icons/fa";
import Collapse from "react-bootstrap/Collapse";

function Contactus() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  
  return (
    <>
  
        <section class="ABT_box testimonial-main sem-padding parallax ">
          <div class="container">
            <div className="row">
              <div class="col-sm-12">
                <div class="heading" data-aos="zoom-in">
                  <h3>Get In Touch With Us</h3>
                  <p className="text-white">Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>
              </div>
              <div class="Chooseus-cardbox">
                <div className="row">
                  <div class="col-md-4 col-sm-12">
                    <div
                      class="cardbox-main"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      <div class="chosseus-img">
                        <FaViber />
                      </div>
                      <h3>
                      <a  href="tel:+ 0731-3589090">+ 0731-3589090</a>
                       </h3>
                      <p className="text-white">
                        {" "}
                        Assistance hours: Monday – Friday, 10:00 AM to 7:00 PM.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div
                      class="cardbox-main"
                      data-aos="fade-up"
                      data-aos-delay="400"
                    >
                      <div class="chosseus-img">
                        <FaEnvelopeOpenText />
                      </div>
                      <h3>
                      <a  href="mailto:info@impetrosys.com">info@impetrosys.com</a>
                     
                        </h3>
                      <p className="text-white">
                        {" "}
                        Our support team will get back to in 24-Hrs during
                        standard business hours.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <div
                      class="cardbox-main"
                      data-aos="fade-up"
                      data-aos-delay="600"
                    >
                      <div class="chosseus-img">
                        <FaDribbble />
                      </div>
                      <h3>
                        <a href="https://impetrosys.com">www.impetrosys.com</a></h3>
                      <p className="text-white">
                        We Are Online: 24*7 to provide solutions for your
                        quiries.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="contact-4">
          <Container>
            <Row>
              <Col lg={6}>
                <div className="contact-suports">
                  <h2>Need Help!</h2>

                  <p>
                    Contact us. 
                  </p>
                </div>
                <Contactform />
              </Col>
              <Col lg={6}>
                <div className="contact-suports" data-aos="zoom-in">
                  <img src={contactsuported} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <div className="row justify-content-center upset shape-numm">
                <div className="common-heading-text-11">
                  <h2>Our offices</h2>
                </div>
                <Col lg={6}>
                  <div className="ad-text">
                    <div
                      className="office-card"
                    
                    >
                      <div
                        className="skyline-img"
                        data-tilt
                        data-tilt-max="4"
                        data-tilt-speed="1000"
                      >
                        <img className="inodre" src={indore} alt="map-imgg" />
                      </div>
                      <div className="office-text">
                        <h4>India</h4>
                        <p>
                          {" "}
                          403-Fourth Floor, Fortune Ambience Business Space,
                          South Tukoganj, Indore, Madhya Pradesh 452001
                        </p>
                        <div className="contc-text">
                      
                          <button
                            target="blank"
                            className="main-btn btn btn-primary mt-2"
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                          >
                            View on Map
                            <FaAngleRight />
                          </button>
                        </div>
                        <Collapse in={open}>
                          <div className="map">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.2949419661322!2d75.87494831413508!3d22.71727628510854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd13e26d019b%3A0x69548871791255d1!2sImpetrosys%20Software%20Solution%20PVT.%20LTD.!5e0!3m2!1sen!2sin!4v1628771971966!5m2!1sen!2sin"
                              width="470"
                              height="400"
                              title="frame1"
                            ></iframe>
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div
                    className="office-card"
                   
                  >
                    <div
                      className="skyline-img"
                      data-tilt
                      data-tilt-max="4"
                      data-tilt-speed="1000"
                    >
                      <img className="inodre" src={newyork} alt="map-imgg" />
                    </div>
                    <div className="office-text">
                      <h4>New York</h4>
                      <p>
                        New York Office- 244 5th Avenue, Suite B-205, New York,
                        NY, 10001, USA
                      </p>
                      <button
                        target="blank"
                        className="main-btn btn btn-primary mt-2"
                        onClick={() => setOpen1(!open1)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open1}
                      >
                        View on Map
                        <FaAngleRight />
                      </button>
                      <Collapse in={open1}>
                        <div className="map">
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.33750346623!2d-73.97968099999999!3d40.6974881!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1655805099277!5m2!1sen!2sin"
                            width="470"
                            height="400"
                            title="frame2"
                          ></iframe>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </Container>
        </section>
   
    </>
  );
}

export default Contactus;
