import React, { useEffect, useState } from "react";
import "./Career.css";
import Careerform from "./Careerform";
import Vacancy from "./Vacancy"
import homeabut from "../../assets/images/homeabut.png";
// import { UploadOutlined } from '@ant-design/icons';
// import { message, Upload } from 'antd';

import ourproduct1 from "../../assets/images/ourproduct1.jpg";
import { Button } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";

import AOS from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";
// import career1 from "../../assets/images/career/career1.jpg";
import career5 from "../../assets/images/career/career5.png";
import career6 from "../../assets/images/career/career6.png";

function Career() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <section className="Carees-main">
        <Container>
          <Row>
            <Col md={12}>
              <div className="Carees-heading text-center" data-aos="fade-up">
                <h6>Let’s get started</h6>
                <h2>Are you ready for a better, more productive business?</h2>
                <p>
                  {" "}
                  Career and Culture at Impetrosys We Provide All Your Needs!
                  Software Consultant || IT Expert || Online Business Solution
                  || Mobile & Web Apps || Products Development
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="career-img101">
          <img src={ourproduct1} alt="" />
        </div>

        {/* <Logoslider /> */}
      </section>
      <section className="Career-second">
        <Container>
          <Row>
            <Col md={8} sm={12}>
              <div className="carres-second-head">
                <h2>Meet The Impetrosys Constellation</h2>
                <div className="bar"></div>
                <p className="mt-3">
                  At IMPETROSYS, we understand that not all talent is created
                  equally – and that the best is often a lot better than the
                  rest. We also know that the need for (sustainable) speed has
                  never been greater. At the heart of extraordinary impact lies
                  a culture of career-integrated hyper learning and
                  extraordinary relationships.
                </p>
                <br></br>
                <p>
                  If you decide to join this bandwagon of outliers of all shapes
                  and sizes who do not want to be liked, but treated as equals
                  in a challenging adventure, constantly awed by new thoughts,
                  ideas and learnings.
                </p>
                <br></br>
                <p>
                  IMPETROSYS is a cluster of the brightest stars working with
                  cutting-edge technologies. Their purpose is anchored in a
                  single truth – bringing real positive changes in an
                  increasingly virtual world. That is{" "}
                </p>
              </div>
            </Col>
            <Col md={4} sm={12}>
              <div className="career-img101">
                <img src={homeabut} alt="img" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
        <Vacancy/>
     
      <section className="career-third">
        <Container>
          <Row>
            <Col md={5} sm={12}>
              <img
                className="career-img101"
                src={career5}
                alt="ImpactLeftIMG"
              />
            </Col>
            <Col md={7} sm={12}>
              <div className="Careers-forth">
                <h3>Benefit’s</h3>
               
                <p>
                  By now, professionals, particularly in the technology sector,
                  know that future-proofing our learning and skills is crucial –
                  but learning as we know it will not suffice. Disruptors of
                  nature of work have been delivered by digital innovations,
                  changing digital-native needs, and a young workforce which
                  only finds true professional joy from impactful work.
                </p>
                <p>
                  We connect your career goals to strategic skills that are a
                  win-win for each and everyone in the IMPETROSYS family. Our
                  Hyperlearning workplace is grounded upon four principles –
                </p>
                <ul>
                  <li>free spirit and emotional positivity</li>
                  <li>agile self-determination</li>
                  <li>psychological and physical safety</li>
                  <li>trust, transparency and open collaboration</li>
                </ul>
                <p>
                  We leverage career-oriented skilling models and optimize our
                  collective intelligence aided with technology for continuous
                  learning, unlearning and relearning at a rapid pace and scale.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="career15-text">
        {/* <Container>
            <Row>
              <Col xl={12}> */}
        <Careerform />
        {/* </Col>
            </Row>
          </Container> */}
      </section>
      <section className="jointalentCommunity">
        <Container>
          <Row>
            <Col md={8} sm={12}>
              <div className="career-tt11 mt-5">
                <h2>Join Our Talent Community</h2>

                <p>
                  Stay connected by joining our network! Enter your e-mail and
                  tell us a bit about yourself, and well keep you informed about
                  upcoming events and opportunities that match your interests.
                </p>
                <button type="Submit" class="main-btn btn btn-primary mt-4">
                  <a href="/#">Join now
                 </a></button>
            
              </div>
            </Col>
            <Col md={4} sm={12}>
              <img
                data-aos="fade-up"
                className="img-fluid"
                src={career6}
                alt="JoinTalentComunity"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Career;
