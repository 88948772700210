import React, { useState, useEffect } from "react";
import Pagination from "react-bootstrap/Pagination";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaRegClock } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa";
import "./Career.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Container } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { Row, Col, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import AOS from "aos";
import "aos/dist/aos.css";
import Modal from "react-bootstrap/Modal";

function Vacancy() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //   pagination
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  const showAlert = () => {
    Swal.fire({
      title: "Thank You !",
      text: "Thanks for taking the time to apply for our position. We appreciate your interest in Impetrosys.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };

  //this is uses for the pdf/image upload in database.
  const [resumefile, setResumefile] = useState();

  const formik = useFormik({
    initialValues: {
      full_name: "",
      email: "",
      phone: "",
      job_title: "",
      message: "",
      resume: null,
      // password: "",
      // confirm_password: ""
    },
    validationSchema: Yup.object({
      full_name: Yup.string()
        // .min(2, "Mininum 2 characters")
        // .max(10, "Maximum 10 characters")
        // .matches(/^\S*$/, 'Empty Whitespace Not Allowed')
        .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      //
      email: Yup.string()
        .email("Invalid email format")
        .matches(/^\S*$/, "Empty Whitespace Not Allowed")
        .required("Required!"),

      // number: Yup.string()

      phone: Yup.string()
        .required(" Required")
        .matches(
          /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
          "Phone number is not valid"
        ),
      //
      job_title: Yup.string()
        // .min(5, "Mininum 5 characters")
        // .max(20, "Maximum 20 characters")
        // .matches(/^\S*$/, 'Empty Whitespace Not Allowed')
        // .matches(/^[A-Za-z\s]+$/, "Only Enter Alphabet")
        .required("Required!"),
      //
      message: Yup.string()
        // .min(5, "Mininum 5 characters")
        // .max(25, "Maximum 25 characters")
        // .matches(/^\S*$/, 'Empty Whitespace Not Allowed')
        .required("Required!"),
    }),
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      showAlert();
      localStorage.setItem("full_name", values.full_name);
      localStorage.setItem("email", values.email);
      localStorage.setItem("phone", values.phone);
      localStorage.setItem("job_title", values.job_title);
      localStorage.setItem("message", values.message);
      localStorage.setItem("resume", values.resume);

      formik.resetForm();
      const bodyFormData = new FormData();
      bodyFormData.append("name", values.full_name);
      bodyFormData.append("email", values.email);
      bodyFormData.append("phone", values.phone);
      bodyFormData.append("job_title", values.job_title);
      bodyFormData.append("message", values.message);
      bodyFormData.append("resume", resumefile);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const Livechaturl = `${process.env.REACT_APP_API_URL}/index.php/Restapi/save_apply_job_details`;
      axios
        .post(Livechaturl, bodyFormData, config)
        .then((result) => {
          showAlert();
          document.getElementById("fUpload").value = "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  useEffect(() => {
    console.log(resumefile);
  }, [resumefile]);

  function checkextension() {
    var file = document.querySelector("#fUpload");
    if (/\.(jpe?g|png|gif|doc|docx|pdf)$/i.test(file.files[0].name) === false) {
      Swal("Please upload .PDF .DOCX .DOC .JPG .JPEG .XLS");
    }
  }

  const handleChange = (e) => {
    setResumefile(e.target.files[0]);
    checkextension();
  };

  return (
    <>
      <div className="vacancy">
        <div className="container">
          <div className="text-center mb-2">
            <h3>Jobs openning</h3>
            <p className="lead">Grow your career with us</p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div class="services-item">
                <div class="services-text">
                  <h3 class="title">Full stack Developer</h3>
                  <p>We believe that design (and you) will be critical to the company's success.</p>
                  <div className="job-portal">
                  <span className="badge bg-secondary">REACT</span>
                    <span className="badge bg-secondary">NODE</span>
                    <span className="badge bg-secondary">TYPESCRIPT</span>
                    <span className="badge bg-secondary">JUNIOR</span>
                    </div>
                  <ul class="course-meta-details list-inline  w-100">
                    <li>
                   
                        <span> <FaUserTie /></span>
                      <p>Mini. 1 Year</p>
                    
                    </li>
                    <li>
                    <span><FaRegClock /></span>
                    
                      <p>Full Time</p>
                      
                    </li>
                    <li>
                    <span><FaMapMarkerAlt /></span>
                      <p>Indore Only</p>
                   
                    </li>
                  </ul>
                  <a
                      onClick={handleShow}
                      className="btn btn-primary stretched-link"
                    >
                      Apply
                    </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="services-item">
                <div class="services-text">
                  <h3 class="title">Front end Developer</h3>
                  <p>We believe that design (and you) will be critical to the company's success.</p>
                  <div className="job-portal">
                  <span className="badge bg-secondary">REACT</span>
                    <span className="badge bg-secondary">NODE</span>
                    <span className="badge bg-secondary">TYPESCRIPT</span>
                    <span className="badge bg-secondary">JUNIOR</span>
                    </div>
                    <ul class="course-meta-details list-inline  w-100">
                    <li>
                   
                        <span> <FaUserTie /></span>
                      <p>Mini. 1 Year</p>
                    
                    </li>
                    <li>
                    <span><FaRegClock /></span>
                    
                      <p>Full Time</p>
                      
                    </li>
                    <li>
                    <span><FaMapMarkerAlt /></span>
                      <p>Indore Only</p>
                   
                    </li>
                  </ul>
                  <a
                      onClick={handleShow}
                      className="btn btn-primary stretched-link"
                    >
                      Apply
                    </a>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div class="services-item">
                <div class="services-text">
                  <h3 class="title">Full stack Developer</h3>
                  <p>We believe that design (and you) will be critical to the company's success.</p>
                  <div className="job-portal">
                  <span className="badge bg-secondary">REACT</span>
                    <span className="badge bg-secondary">NODE</span>
                    <span className="badge bg-secondary">TYPESCRIPT</span>
                    <span className="badge bg-secondary">JUNIOR</span>
                    </div>
                    <ul class="course-meta-details list-inline  w-100">
                    <li>
                   
                        <span> <FaUserTie /></span>
                      <p>Mini. 1 Year</p>
                    
                    </li>
                    <li>
                    <span><FaRegClock /></span>
                    
                      <p>Full Time</p>
                      
                    </li>
                    <li>
                    <span><FaMapMarkerAlt /></span>
                      <p>Indore Only</p>
                   
                    </li>
                  </ul>
                  <a
                      onClick={handleShow}
                      className="btn btn-primary stretched-link"
                    >
                      Apply
                    </a>
                </div>
              </div>
            </div>
          </div>

          

          <Pagination>
            <Pagination.First />
            {items}
            <Pagination.Last />
          </Pagination>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Full Stack Developer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6} sm={6}>
                <lable className="Request_field">Name</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="full_name"
                    placeholder="Enter Your Name"
                    autoComplete="off"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.full_name && formik.touched.full_name && (
                    <p className="errormessage">{formik.errors.full_name}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <lable className="Request_field">Email</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="errormessage">{formik.errors.email}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <lable className="Request_field">Phone</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="phone"
                    name="phone"
                    country="US"
                    placeholder="Enter Your Mobile Numner"
                    autoComplete="off"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <p className="errormessage">{formik.errors.phone}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} sm={6}>
                <lable className="Request_field">Applying for Of The</lable>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    name="job_title"
                    placeholder="Enter Your Job Title"
                    autoComplete="off"
                    value={formik.values.job_title}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.job_title && formik.touched.job_title && (
                    <p className="errormessage">{formik.errors.job_title}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
                <div className="field file-upload">
                  <lable className="Request_field">Upload File</lable>

                  <input
                    type="file"
                    name="your-file"
                    className="form-control"
                  />
                </div>
              </Col>
              <Col md={12}>
                <lable className="Request_field">Message</lable>
                <Form.Control
                  className="form-control"
                  as="textarea"
                  placeholder="Enter Your Message"
                  style={{ height: "125px" }}
                  type="message"
                  name="message"
                  autoComplete="off"
                  rows={4}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
                {formik.errors.message && formik.touched.message && (
                  <p className="errormessage">{formik.errors.message}</p>
                )}
              </Col>
            </Row>
            <div className="form-bt">
              <Button type="Submit" className="main-btn btn btn-primary">
                {" "}
                APPLY NOW
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Vacancy;
