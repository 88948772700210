import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Contactus from "../Component/Contact/Contactus";


const Contact = () => {
  return (
    <>
    <Header/>
    <Contactus/>
  
      <Footer1 />
      <Footer />
    </>
  );
};

export default Contact;
