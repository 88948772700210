import React, {useEffect } from "react";
import "./Service.css";
import service from "../../assets/images/service.png";
import {
  FaHeadSideVirus,
  FaCodepen,
  FaHornbill,
  FaMobileAlt,
  FaLaptop,
  FaSith,
} from "react-icons/fa";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Container, Row, Col } from "react-bootstrap";
import service99 from "../../assets/images/home/service99.png";
import service8 from "../../assets/images/home/service8.png";
import service10 from "../../assets/images/home/service10.png";
import service11 from "../../assets/images/home/service11.png";
import service12 from "../../assets/images/home/service12.png";
import service13 from "../../assets/images/home/service13.png";
import service14 from "../../assets/images/home/service14.png";
import service7 from "../../assets/images/home/service7.png";
import feature from "../../assets/images/home/feature.png";

import AOS from "aos";
import "aos/dist/aos.css";

function Services() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>

      <div className="services-t1">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="section-title"data-aos="zoom-in">
                <h2 className="text-white">We Offer Our Dedicated Services To World</h2>
                <div className="bar"></div>
                <p className="text-white"
                style={{width:"690px"}}>
                  Serving something to the world is a big responsibilty and
                  quite challenging You customers satisfection prove that what
                  quality of services you are offering them
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-2 mt-3 mb-3">
        <Container>
          <Row>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"
                >
                <div className="icon">
                  <img src={service7} />
                </div>
                <h3>
                  <a href="#">Web Development</a>
                </h3>
                <p>
                  We make a fresh, functional, and mobile ready website that
                  will help you reach all of your online marketing goals.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"
                >
                <div className="icon">
                  <img src={service99} />
                </div>
                <h3>
                  <a href="#">Mobile Development</a>
                </h3>
                <p>
                  We develop feature rich android and ios app that technically
                  advanced and provide a great experience for your customers.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"
            
             >
                <div className="icon">
                  <img src={service8} />
                </div>
                <h3>
                  <a href="#">Custom Software</a>
                </h3>
                <p>
                  We offer a creative, well-designed, web Software for small,
                  medium and large-scale businesses.e-scale businesses.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
            <div className="single-solutions-box"
              
             >
                <div className="icon">
                  <img src={service10} />
                </div>
                <h3>
                  <a href="#">Cloud Consulting</a>
                </h3>
                <p>
                We offer a quick and easy creation procedure as well as a
                deep, flexible, and granular custom one.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"data-aos="fade-up"
                 data-aos-delay="400">
                <div className="icon">
                  <img src={service14} />
                </div>
                <h3>
                  <a href="#">DevOps Automation</a>
                </h3>
                <p>
                  Our Deployment Automation Services include deployment metrics,
                  single tool deployment in all environments
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"
              data-aos="fade-up"
              data-aos-delay="600">
                <div className="icon">
                  <img src={service11} />
                </div>
                <h3>
                  <a href="#">Branding & Promotion</a>
                </h3>
                <p>
                  With extensive experience in the industry. We design our
                  custom branding services to develop a unique identity of your
                  business.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"data-aos="fade-up"
                 data-aos-delay="800">
                <div className="icon">
                  <img src={service12} />
                </div>
                <h3>
                  <a href="#">Software Prototyping</a>
                </h3>
                <p>
                  we optimize the page layout, illustrating structural
                  arrangement and alignments meticulously.
                </p>
              </div>
            </Col>
            <Col xl={3} lg={6} md={6}>
              <div className="single-solutions-box"
              data-aos="fade-up"
              data-aos-delay="1000">
                <div className="icon">
                  <img src={service13} />
                </div>
                <h3>
                  <a href="#">Prodcut Designing</a>
                </h3>
                <p>
                  We make a fresh, functional act as a liaison between
                  designers, engineers, help create wireframes and prototypes.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="services-service-new">
        <Swiper
          modules={[Autoplay]}
            autoplay={true}
          // className="mySwiper"
          breakpoints={{
            640: { slidesPerView: 1, spaceBetween: 24, resistanceRatio: 0.85 },
            575: {
              slidesPerView: 1,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
            300: {
              slidesPerView: 1,
              spaceBetween: 24,
              resistanceRatio: 0.85,
            },
          }}
          spaceBetween={10}
        >
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service7} />
              </div>
              <h3>
                <a href="#">Web Development</a>
              </h3>
              <p>
                We make a fresh, functional, and mobile ready website that will
                help you reach all of your online marketing goals.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service99} />
              </div>
              <h3>
                <a href="#">Mobile Development</a>
              </h3>
              <p>
                We develop feature rich android and ios app that technically
                advanced and provide a great experience for your customers.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service8} />
              </div>
              <h3>
                <a href="#">Custom Software</a>
              </h3>
              <p>
                We offer a creative, well-designed, web Software for small,
                medium and large-scale businesses.e-scale businesses.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service10} />
              </div>
              <h3>
                <a href="#">Cloud Consulting</a>
              </h3>
              <p>
                We offer a quick and easy creation procedure as well as a deep,
                flexible, and granular custom one.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service14} />
              </div>
              <h3>
                <a href="#">DevOps Automation</a>
              </h3>
              <p>
                Our Deployment Automation Services include deployment metrics,
                single tool deployment in all environments
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service11} />
              </div>
              <h3>
                <a href="#">Branding & Promotion</a>
              </h3>
              <p>
                With extensive experience in the industry. We design our custom
                branding services to develop a unique identity of your business.
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service12} />
              </div>
              <h3>
                <a href="#">Software Prototyping</a>
              </h3>
              <p>
                we optimize the page layout, illustrating structural arrangement
                and alignments meticulously.
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="single-solutions-box">
              <div className="icon">
                <img src={service13} />
              </div>
              <h3>
                <a href="#">Prodcut Designing</a>
              </h3>
              <p>
                We make a fresh, functional act as a liaison between designers,
                engineers, help create wireframes and prototypes.
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <section class="section-ptb bg-secondary">
        <div class="container">
            <div className="section-title">
              <h5>
                Technologies Helps Us To Build A World Class Solution To Our
                Customers
              </h5>
              <div className="service-new-bar">
              <div className="bar"></div>
              </div>
            </div>
          <div class="row">
              <div class="col-md-4">
              <div class="feature-step-wrapper">
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number"><FaCodepen /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title">Blockchain Technology</h5>
                    <p class="feature-timeline-content">
                    Blockchain is a shared, immutable ledger that facilitates the
                  process of recording transactions and tracking assets in a
                  business network,
                    </p>
                  </div>
                </div>
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number"><FaHornbill /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title"> loT and ML</h5>
                    <p class="feature-timeline-content">
                    loT and machine learning deliv insights otherwise hidden in
                  data for rapid, automated responses and improved decision
                  making.
                    </p>
                  </div>
                </div>
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number"> <FaHeadSideVirus /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title">Artificial Intelligence</h5>
                    <p class="feature-timeline-content">
                    Artificial intelligence Is the simulation of human
                    intelligence processes by machines, especially computer
                    systems.
                    </p>
                  </div>
                </div>
                
              </div>
            </div>
            <div class="col-md-4">
            <div className="services-0">
                <img src={feature} />
              </div>
            </div>
            <div class="col-md-4">
              <div class="feature-step-wrapper">
               
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number"><FaMobileAlt /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title">Mobile Technology</h5>
                    <p class="feature-timeline-content">
                    We have Native and Hybrid techs like Android Java, Kotline,
                  Swift, Flutter and React Native.
                    </p>
                  </div>
                </div>
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number"> <FaLaptop /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title">Web Technologies</h5>
                    <p class="feature-timeline-content mb-2">
                    We work with latest tech stacks like MEAN, MERN & Fullstack,
                  combinations of Angulo*, React.js, Nodejs. We also work with
                  php frameworks
                    </p>
                  </div>
                </div>
                <div class="feature-timeline feature-timeline-light">
                  <div class="feature-info-number">
                    <span class="feature-timeline-number">  <FaSith /></span>
                  </div>
                  <div class="feature-info-content">
                    <h5 class="feature-timeline-title">Ul/UX Tools</h5>
                    <p class="feature-timeline-content mb-2">
                    Our strong tools for 1.INX are PS, Illustrator, Coral. We also
                  use the online tool for prototying like Figma, Invision etc.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>    
      <section className="service-provide">
        <Container>
          <Row>
          <Col lg={5} md={5}>
              <div className="service-img-"data-aos=" fade-right ">
                <img src={service} />
              </div>
            </Col>
            <Col lg={7} md={7}>
            <div className="service-text-223">
                <div className="service-text-22"data-aos="fade-up">
                  <h6>It Support For Business</h6>
                  <h1>Preparing for your success trusted IT services.</h1>
                  <p>
                    IT-Solve is the partner of choice for many of the world's
                    leading and solving enterprises, SMEs and technology
                    challengers. We help businesses elevate their value through
                    custom software development.
                  </p>
                </div>
                <div className="restly-about-dec">
                <ul>
                <li>Aene aneque.</li>
                <li>Ut eu lectsuat.</li>
                <li>Aenean lacinia.</li>
                <li>Praesent vitae.</li>
                <li>IOS</li>
                <li>Android</li>
                <li>Microsoft</li>
                <li>Application</li>
                </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="differentiatorSec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="services-t21-text">
                  <div className="commonTitle">
                    <h2 className="commonTitle__title text-dark">
                      <strong> IMPETROSYS</strong> Differentiators
                    </h2>
                  </div>
                <div className="diffrent">
                  <p>
                    Leveraging deep technology expertise, strong domain
                    knowledge and an innovation-led approach, we help boost
                    business resiliency. From development to modernization and
                    the maintenance of high-quality, secure applications, we use
                    emerging technologies to help you embrace new business
                    paradigms at an accelerated pace. With a robust partner
                    ecosystem and skilled resources cross-trained in next-gen
                    technologies, we bring expertise and experience to deliver
                    application services with the flexibility, freedom, and
                    support you need.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
      </>
  );
}

export default Services;
