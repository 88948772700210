import React, { useEffect } from "react";
import "./Counter.css";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

function Counter() {
  const [focus, setFocus] = React.useState(false);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
      <section className="Counter">
        <Container>
          <div className="counte-img">
            <div className="section-title-counter">
              <h2>
                We Always try to Understand our Customers Expectation Without
                any question, the voice of
                <h2 className=" counter-set mt-1">
                  the customers is important
                </h2>
              </h2>
              <div className="bar"></div>

              <p>
                Customers opinions and feedbacks are two of the most essential
                factors that validate decisions within your business, allowing
                you to become more equipped to meet their specific and immediate
                needs instead of basing your strategy on assumptions.
              </p>
            </div>
          </div>

          <div class="row text-center">
            <Col lg={3} md={6} sm={6} xs={6}>
              <div className="counter">
                <h3>
                  <CountUp
                    start={focus ? 0 : null}
                    end={350}
                    duration={5}
                    redraw={true}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <VisibilitySensor
                          onChange={(isVisible) => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                        >
                          <a>+Project</a>
                        </VisibilitySensor>
                      </div>
                    )}
                  </CountUp>
                </h3>

                <p>Delivered Successfully</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={6}>
              <div class="counter">
                <h3>
                  <CountUp
                    start={focus ? 0 : null}
                    end={325}
                    duration={5}
                    redraw={true}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <VisibilitySensor
                          onChange={(isVisible) => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                        >
                          <a>+Products</a>
                        </VisibilitySensor>
                      </div>
                    )}
                  </CountUp>
                </h3>
                <p> We Built so far</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={6}>
              <div class="counter">
                <h3>
                  <CountUp
                    start={focus ? 0 : null}
                    end={10}
                    duration={5}
                    redraw={true}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <VisibilitySensor
                          onChange={(isVisible) => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                        >
                          <a>+Yrs</a>
                        </VisibilitySensor>
                      </div>
                    )}
                  </CountUp>
                </h3>

                <p>Of experience</p>
              </div>
            </Col>
            <Col lg={3} md={6} sm={6} xs={6}>
              <div class="counter">
                <h3>
                  <CountUp
                    start={focus ? 0 : null}
                    end={35}
                    duration={5}
                    redraw={true}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                        <VisibilitySensor
                          onChange={(isVisible) => {
                            if (isVisible) {
                              setFocus(true);
                            }
                          }}
                        >
                          <a>+Countries </a>
                        </VisibilitySensor>
                      </div>
                    )}
                  </CountUp>
                </h3>

                <p>Wherer We Worked</p>
              </div>
            </Col>
          </div>
          <Row>
            <Col xl={8} lg={12} md={12} sm={12} className="offset-2 mt-4">
              <div class="contact-cta-box">
                <div className="ct-conu">
                  <h3> Do you have an idea ?</h3>
                  <p>
                    {" "}
                    Don't hesitate to contact us,we may makeit into reality for
                    you!
                  </p>
                </div>
                <div className="mat-text">
                  <Button type="Submit" className="main-btn">
                    <Link to="/Contact">
                      Contact Us <FaAngleRight />
                    </Link>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
   
  );
}

export default Counter;
