import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Ourproduct.css";
import support from "../../assets/images/support.jpg";
import {
  FaMobileAlt,
  FaConnectdevelop,
  FaFileSignature,
  FaHandHoldingWater,
  FaUsersCog,
  FaRecycle,
  FaCubes,
} from "react-icons/fa";
import { BsTools } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
import "swiper/css/autoplay";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaLandmark,
  FaStudiovinari,
  FaWpforms,
  FaBone,
  FaCompressArrowsAlt,
  FaDraftingCompass,
  FaLongArrowAltRight,
} from "react-icons/fa";

function OurProduct() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  return (
    <>
 
        <div className="ur-poroduct">
          <div className="services-t1">
            <Container>
              <div className="section-title" data-aos="zoom-in">
                <h2 className="text-white">Our Products</h2>
                <div className="bar"></div>
                <p className="text-white">
                  {" "}
                  One of the best quality product we provide for the clients.
                </p>
              </div>
            </Container>
          </div>
          </div>
          <div className="our-product-update">
            <Container>
              <Row>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon">
                      <i>
                        <FaLandmark />
                      </i>
                    </div>
                    <h3>Banking Solution for Mercantile</h3>
                    <p>
                      Providing business application solutions to the banking,
                      capital markets industries and also provide Banking
                      solution for mercantile.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon">
                      <i>
                        <FaStudiovinari />
                      </i>
                    </div>
                    <h3>Jewellery Management System</h3>
                    <p>
                      Development of a system to computerize the major
                      transactions in jewellery like, purchases, sales and bill
                      preparation.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon bg-c679e3">
                      <i>
                        <FaWpforms />
                      </i>
                    </div>
                    <h3>POS Billing and Stock management System</h3>
                    <p>
                      We provide advanced billing software and stock management
                      tool, which comes with advanced features like Taxes,
                      Inventory, Addons, multi stores, advance reports,
                      invoices, barcodes
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon bg-c679e3">
                      <i>
                        <FaBone />
                      </i>
                    </div>
                    <h3>HRMS</h3>
                    <p>
                      We provide HRMS software that will enable
                      enterprises/corporates to automate their entire post
                      recruitment HR functions from onboarding to final
                      separation seamlessly..
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon bg-eb6b3d">
                      <i>
                        <FaCompressArrowsAlt />
                      </i>
                    </div>
                    <h3>Inventory Management System</h3>
                    <p>
                      {" "}
                      We build the software that provide the features which you
                      track your goods throughout your entire supply chain, from
                      purchasing to production to end sales.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
                <Col lg={6} md={6}>
                  <div
                    className="single-features"
                  
                  >
                    <div className="icon bg-eb6b3d">
                      <i>
                        <FaDraftingCompass />
                      </i>
                    </div>
                    <h3>Asset Maintenance Management System</h3>
                    <p>
                      Our IT Asset Management software (ITAM) platform gives you
                      complete visibility and control of your assets.It helps
                      you track and manage assets throughout their lifecycle.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="our-products-updated-1">
            <Container>
              <Swiper
                slidesPerView={1}
                modules={[Autoplay]}
           
                autoplay={true}
                spaceBetween={10}
                delay="4500"
                // navigation={true}

                className="mySwiper"
              >
                <SwiperSlide>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon">
                      <i>
                        <FaLandmark />
                      </i>
                    </div>
                    <h3>Banking Solution for Mercantile</h3>
                    <p>
                      Providing business application solutions to the banking,
                      capital markets industries and also provide Banking
                      solution for mercantile.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon">
                      <i>
                        <FaStudiovinari />
                      </i>
                    </div>
                    <h3>Jewellery Management System</h3>
                    <p>
                      Development of a system to computerize the major
                      transactions in jewellery like, purchases, sales and bill
                      preparation.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="single-features"
                  
                  >
                    <div className="icon bg-c679e3">
                      <i>
                        <FaWpforms />
                      </i>
                    </div>
                    <h3>POS Billing and Stock management System</h3>
                    <p>
                      We provide advanced billing software and stock management
                      tool, which comes with advanced features like Taxes,
                      Inventory, Addons, multi stores, advance reports,
                      invoices, barcodes
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div
                    className="single-features"
                 
                  >
                    <div className="icon bg-c679e3">
                      <i>
                        <FaBone />
                      </i>
                    </div>
                    <h3>HRMS</h3>
                    <p>
                      We provide HRMS software that will enable
                      enterprises/corporates to automate their entire post
                      recruitment HR functions from onboarding to final
                      separation seamlessly..
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="single-features"
                   
                  >
                    <div className="icon bg-eb6b3d">
                      <i>
                        <FaCompressArrowsAlt />
                      </i>
                    </div>
                    <h3>Inventory Management System</h3>
                    <p>
                      {" "}
                      We build the software that provide the features which you
                      track your goods throughout your entire supply chain, from
                      purchasing to production to end sales.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div
                    className="single-features"
                    
                  >
                    <div className="icon bg-eb6b3d">
                      <i>
                        <FaDraftingCompass />
                      </i>
                    </div>
                    <h3>Asset Maintenance Management System</h3>
                    <p>
                      Our IT Asset Management software (ITAM) platform gives you
                      complete visibility and control of your assets.It helps
                      you track and manage assets throughout their lifecycle.
                    </p>
                    <Link to="/Product">
                      Lern More <FaLongArrowAltRight />
                    </Link>
                  </div>
                </SwiperSlide>
              </Swiper>
            </Container>
          </div>
        
        <section className="our-products-fasted">
          <Container>
            <Row>
              <Col md={6}>
                <div className="ourproduct-25" >
                  <img src={support} />
                </div>
              </Col>
              <Col md={6}>
                <div className="our-text-55" >
                  <h2>What's next? our latest client stories</h2>
                  <p>We love what we do. Our clients tell us the same thing.</p>
                </div>
                <Swiper
                  slidesPerView={1}
                  delay="4500"
                  modules={[Autoplay]}
           
                  autoplay={true}
                >
                  <SwiperSlide>
                    <div className="newslide-2">
                      <h1>Fastest-growing IT software agency</h1>
                      <p>
                        We transform your business to provide a competitive edge
                        in the digital landscape leveraging the latest
                        technologies. Let's collaborate and craft your next
                        success story!
                      </p>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="newslide-2">
                      <h1>Dedicated Client Support</h1>
                      <p>
                        Our software development and support teams contribute
                        enormously to our success by going to great lengths to
                        assist our customers.
                      </p>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="newslide">
          <div className="ml-about-content" data-aos="zoom-in">
            <h2>Technology That Serves Your Purpose</h2>
            <p className="text-white">
              Technology that we provie you is increased efficiency and
              flexibility for your workspace and aslo increased the productivity
              of your bussiness.
            </p>
          </div>
          <Container>
            <Row>
              <Col md={12}>
                <div class="category-wrapper">
                  <div class="grid-wrapper">
                
                    <div
                      class="category-icons"
                    
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaMobileAlt />
                          </i>
                        </div>
                        <span class="category-title">Mobile POS</span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                     
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaConnectdevelop />
                          </i>
                        </div>
                        <span class="category-title">IOT Enabled Software</span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                    
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaFileSignature />
                          </i>
                        </div>
                        <span class="category-title">Requirement Analysis</span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                    
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <BsTools />
                          </i>
                        </div>
                        <span class="category-title">
                          Maintenance & Support
                        </span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                    
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaHandHoldingWater />
                          </i>
                        </div>
                        <span class="category-title">SEO/SEM Solutions</span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                   
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaUsersCog />
                          </i>
                        </div>
                        <span class="category-title">
                          On Domain ERP Solution
                        </span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                      
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaRecycle />
                          </i>
                        </div>
                        <span class="category-title">
                          Product Lifecycle Management
                        </span>
                      </div>
                    </div>
                    <div
                      class="category-icons"
                    
                    >
                      <div class="category-item">
                        <div class="item-icon">
                          <i>
                            <FaCubes />
                          </i>
                        </div>
                        <span class="category-title">Inventory Management</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    
    </>
  );
}

export default OurProduct;
