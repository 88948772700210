import React, { useEffect } from "react";
import "./Industri.css";
import Industry from "../Homes/Industry/Industry";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaSortUp,
  FaConnectdevelop,
  FaJsfiddle,
  FaMedapps,
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";



function Industri() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
  
        <section className="industries-0">
          <Container>
            <Row>
              <Col md={12}>
                <div className="industries-00">
                  <div className="indtext-0 text-center" data-aos="zoom-in">
                    <h2>
                      Focus is having the unwavering attention to complete what
                      you set out to do.{" "}
                    </h2>
                    <p className="text-center text-white">
                      The sad thing is the majority of people have no clue about
                      what they truly want. They have no clarity. When asked the
                      question, responses will be superficial at best, and at
                      worst, will be what someone else wants for them.
                    </p>
                  </div>
                  <div class="circle">
                    {" "}
                    <FaSortUp />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <Industry />
        <section className="ind-7">
          <Container>
            <Row>
              <div className="ind-text-5">
                <h2>Smart strategy & excellent performance</h2>
                <div className="bar"></div>
                <p className="mt-2">
                  Process that guarantees high productivity and profitability
                  for your solution..
                </p>
              </div>
              <Col md={4}>
                <div className="indt" data-aos="fade-up" data-aos-delay="400">
                  <div className="feature-info-icon">
                    <i>
                      <FaMedapps />
                    </i>
                  </div>
                  <div className="industries-text-39">
                    <h5>Data Analysis</h5>
                    <p>
                      We conduct thorough data collection and analysis of your
                      institution’s current strengths and differentiators.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="indt" data-aos="fade-up" data-aos-delay="600">
                  <div className="feature-info-icon">
                    <i>
                      <FaJsfiddle />
                    </i>
                  </div>
                  <div className="industries-text-39">
                    <h5>Making a plan</h5>
                    <p>
                      We will work together with you to develop a plan that is
                      actionable, feasible and tailored to your needs.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="indt" data-aos="fade-up" data-aos-delay="800">
                  <div className="feature-info-icon">
                    <i>
                      <FaConnectdevelop />
                    </i>
                  </div>
                  <div className="industries-text-39">
                    <h5>Deliver Result</h5>
                    <p>
                      Our role in this step is to provide expertise where
                      needed, to augment select initiatives as desired.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="delevery-text">
          <Container>
            <div className="ind-text-31 mb-3">
              <h2>Delivering Results</h2>
              <p>
                This is the first step of the process! Key: Notice how you felt
                excited and optimistic when you did this? The reason is simple.
              </p>
            </div>
            <Row>
              <Col md={7}>
                <div className="ind-text-33">
                  <p>
                    The first thing to remember about success is that it is a
                    process – nothing more, nothing less. There is really no
                    magic to it and it’s not reserved only for a select few
                    people. As such, success really has nothing to do with luck,
                    coincidence or fate. It really comes down to understanding.
                    select few people. As such, success really has nothing to do
                    with luck, coincidence or fate. It really comes down to
                    understanding.
                  </p>
                </div>
                <Row>
                  <Col sm={6}>
                    <div className="ind-text-34">
                      <ul>
                        <li>Having clarity of purpose</li>
                        <li>We know that the Law</li>
                        <li>We will attract confusion</li>
                        <li>The sad thing is the majority</li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="ind-text-35">
                      <ul>
                        <li>Having clarity of purpose</li>
                        <li>We know that the Law</li>
                        <li>We will attract confusion</li>
                        <li>The sad thing is the majority</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={5}>
                <div className="ind-text-32">
                  <h1>81%</h1>
                  <p>A totally new way to grow your business</p>
                </div>
                <div className="ind-text-32 new">
                  <h1>Improved</h1>
                  <p>We are adding extra value for your business</p>
                </div>
                <div className="ind-text-32 new1">
                  <h1>Increased</h1>
                  <p>
                    Award-winning website design & creative digital agency
                    services
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    
    </>
  );
}

export default Industri;
