import React from "react";
import { useRoutes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import About from "../src/Pages/About";
import Services from "./Pages/Services";
import Career from "./Pages/Career";
import Industries from "../src/Pages/Industries";
import Ourproducts from "../src/Pages/Our_products";
// import Product from "../src/Component/OurProduct/Products/Product";
import Projectdetails from "../src/Component/Homes/Projects/Projectdetails";
import Allproject from "../src/Component/Homes/Projects/Allproject";
import Request from "./Pages/Request";
import Contact from "./Pages/Contact";

export default function Router() {
  // <HomePage />
  return useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/About", element: <About /> },
    { path: "/Services", element: <Services /> },
    { path: "/Industries", element: <Industries /> },
    { path: "/Our_products", element: <Ourproducts /> },
    // { path: "/Product", element: <Product /> },
    { path: "/Allproject", element: <Allproject /> },
    { path: "/Projectdetails", element: <Projectdetails /> },
    { path: "/Career", element: <Career /> },
    { path: "/Request", element: <Request /> },
    { path: "/Contact", element: <Contact /> },
  ]);
}
