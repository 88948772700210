import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
import Requestform from "../Component/Request/Requestform";


const Request = () => {
  return (
    <>
    <Header/>
    <Requestform/>
  
      <Footer1 />
      <Footer />
    </>
  );
};

export default Request;
