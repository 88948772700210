import React from "react";
import Header from "../Common/header/Header"
import Footer from "../Common/footer/Footer";
import Footer1 from "../Common/footer/Footer1";
 import OurProduct from "../Component/OurProduct/OurProduct";

const Our_products = () => {
  return (
    <>
    <Header/>
    <OurProduct/>
   
      <Footer1 />
      <Footer />
    </>
  );
};

export default Our_products;
